<template>
    <div class="approved-container">
        <div class="approved-content">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 3">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackFour">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackThree">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">小测列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{paperName}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{studentName}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">小测列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{paperName}}</el-breadcrumb-item>
            </el-breadcrumb>

            <el-scrollbar class="approved-detail" style="height: 100%" :native="false">
                <div class="detail-top">
                    <div class="top-left">
                        <p class="title">{{paperName}}</p>
                        <p class="info">
                            答题人：{{studentName}}
                            交卷时间：{{paperDetail.submitTime}}
                        </p>
                    </div>
                    <div class="top-right">
                        <span class="approved-status" v-if="role === 3">批阅中</span>
                        <span class="approved-status" v-else-if="role === 4">未批阅</span>
                    </div>
                </div>
                <div class="detail-data">
                    <div class="data-left">
                        <div class="left-icon">
                            <i class="iconfont">&#xe724;</i>
                        </div>
                        <div class="left-info">
                            <span class="score">总分：{{paperDetail.paperScore}}分</span>
                            <span class="score">
                                得分：{{studentScoreTotal}}分
                            </span>
                        </div>
                    </div>
                    <div class="data-table">
                        <div class="row">
                            <div class="item"></div>
                            <div class="item">答对</div>
                            <div class="item">答错</div>
                            <div class="item">未答</div>
                            <div class="item">得分</div>
                        </div>
                        <div class="row" :class="{'row-2': !scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered}" v-for="(scoreItem, scoreKey) in scoreList">
                            <div class="item">
                                {{scoreItem.title}}
                                <span class="gray">({{scoreItem.count}}道题)</span>
                            </div>
                            <div class="item" v-if="scoreItem.right > -1">{{scoreItem.right}} 道</div>
                            <div class="item" v-if="scoreItem.wrong > -1">{{scoreItem.wrong}} 道</div>
                            <div class="item" v-if="scoreItem.unanswered > -1">{{scoreItem.unanswered}} 道</div>
                            <div class="item" :class="{'item-full': !scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered}">
                                <span v-if="!scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered">得分：</span>
                                <el-input v-if="scoreKey === 'askAnswer'" class="score-input" :value="getAskAnswerScore(scoreItem.score)" disabled style="width: 50px"></el-input>
                                <span v-else>{{scoreItem.score}}分</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-question">
                    <div class="question-type-item">
                        <div class="question-type-title">
                            <span class="title">问答题</span>
                            <span class="subtitle">(共{{questionTypeList.length}}题，共{{getTypeTotal(questionTypeList)}}分)</span>
                        </div>
                        <div class="subject-item" v-for="(questionTypeItem, questionTypeIndex) in questionTypeList">
                            <div class="subject-answer">
                                <div class="subject-left">
                                    <div class="subject-title">
                                        <span>{{questionTypeIndex + 1}}.</span>
                                        <span class="score">({{questionTypeItem.score}}分)</span>
                                        <span class="title" v-html="questionTypeItem.topic.title"></span>
                                    </div>
                                    <div class="answer-wrapper">
                                        <div class="wrapper-left">
                                            <span class="answer-tags">学生答案</span>
                                        </div>
                                        <div class="answer-content">
                                            <div class="answer">{{questionTypeItem.topic.options.student_value}}</div>
                                            <div class="score" v-if="role === 3">
                                                得分 <el-input v-model="questionTypeItem.student_score" placeholder="请输入分数" style="width: 140px;"
                                                             @blur="getItemScore(questionTypeItem)"></el-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="subject-right">
                                    <el-button type="primary" size="medium" @click="questionTypeItem.showAnalysis = !questionTypeItem.showAnalysis">
                                        题目解析<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                </div>
                            </div>
                            <div class="subject-analysis" v-show="questionTypeItem.showAnalysis">
                                题目解析：<span v-html="questionTypeItem.topic.analyse"></span>
                            </div>
                        </div>
                    </div>
                    <div class="detail-commit" v-if="role === 3">
                        <el-button type="primary" @click="submitBtn">提交批阅</el-button>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                quizId: Number(this.$route.query.quizId ) || '',
                classId: Number(this.$route.query.classId ) || '',
                studentId: Number(this.$route.query.studentId ) || '',
                className: this.$route.query.className || '',
                paperName: this.$route.query.paperName || '',
                studentName: this.$route.query.studentName || '',
                scoreList: [],
                paperDetail: {
                    paperScore: '',
                    studentScore: '',
                    submitTime: '',
                    quizRecordId: '',
                },
                questionTypeList: [],
                studentScoreTotal: 0,
                role: Number(localStorage.getItem('role')),
            }
        },
        mounted() {
            this.getApprovedDetail();
        },
        methods: {
            getTypeTotal(list) {
                let total = 0;
                list.forEach(item => {
                    total += Number(item.score)
                })
                return total
            },
            getApprovedDetail() {
                let params = {
                    quiz_id: this.quizId,
                    s_id: this.studentId,
                }
                this.$shttp.axiosGetBy(this.$api.seeUnapprovedStudentQuiz, params, (res) => {
                    if (res.code === 200) {
                        this.scoreList = res.data.info;
                        this.paperDetail.submitTime = res.data.submit_time
                        this.paperDetail.paperScore = res.data.paper_total
                        this.paperDetail.studentScore = res.data.student_score
                        this.paperDetail.quizRecordId = res.data.quiz_record_id
                        let askAnswerContent = res.data.askAnswer
                        askAnswerContent.forEach(item => {
                            item.showAnalysis = false
                            item.student_score = ''
                        })
                        this.questionTypeList = askAnswerContent
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getItemScore(item) {
                if (Number(item.student_score) > Number(item.score)) {
                    item.student_score = Number(item.score)
                    this.$message.error('得分不能大于题目分值，已设置为题目分值');
                }
            },
            getAskAnswerScore() {
                let askAnswerScore = 0;
                this.questionTypeList.forEach(item => {
                    askAnswerScore += Number(item.student_score)
                })
                this.studentScoreTotal = askAnswerScore + this.paperDetail.studentScore;
                return askAnswerScore
            },
            submitBtn() {
                let questionContent = this.questionTypeList;
                for (let i = 0; i < questionContent.length; i++) {
                    if (questionContent[i].student_score === '') {
                        this.$message.warning('还有题目没有设置得分')
                        return;
                    // } else {
                    //     if (Number(questionContent[i].student_score) > Number(questionContent[i].score)) {
                    //         this.$message.error('该题得分大于该题目分数');
                    //         return
                    //     }
                    }
                }
                this.$confirm('是否要提交批阅，提交之后无法再批阅', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        let askAnswerContent = this.questionTypeList
                        let teacherScore = {};
                        teacherScore = askAnswerContent.map(item => {
                            let obj = {
                                id: item.id
                            }
                            obj.score = item.student_score
                            return obj
                        })
                        let params = {
                            quiz_record_id: this.paperDetail.quizRecordId,
                            teacher_score: JSON.stringify(teacherScore),
                        }
                        this.$shttp.axiosPost(this.$api.TeacherCheckAskAnswer, params, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg)
                                this.$router.push({path: '/teacherClass/quizList', query: {classId: this.classId, className: this.className}})
                            } else {
                                this.$message.success(res.msg)
                            }
                        })
                    }).catch(() => {
                        this.$message.info('已取消提交')
                });
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
            goBackFour() {
                this.$router.go(-4)
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .approved-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .approved-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .approved-detail {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .detail-top {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .top-left {
                        width: 1%;
                        flex: 1;
                        p {
                            margin: 0;
                            &.title {
                                font-size: 16px;
                            }
                            &.info {
                                color: #666;
                            }
                        }
                    }
                    .top-right {
                        margin-left: 20px;
                        .approved-status {
                            color: #fff;
                            display: inline-block;
                            padding: 0 20px;
                            line-height: 32px;
                            background: #F01414;
                            border-radius: 16px;
                        }
                    }
                }
                .detail-data {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .data-left {
                        color: #fff;
                        padding: 40px 20px;
                        margin-right: 20px;
                        background: linear-gradient(49deg,rgba(107, 192, 255, 1),rgba(58, 140, 246, 1));
                        box-shadow: 0 4px 8px 0 rgba(20,55,186,0.2);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .left-icon {
                            width: 50px;
                            height: 50px;
                            background: #fff;
                            border-radius: 10px;
                            line-height: 50px;
                            text-align: center;
                            .iconfont {
                                color: #409eff;
                                font-size: 28px;
                            }
                        }
                        .left-info {
                            margin-left: 10px;
                            span {
                                display: block;
                                &.score {
                                    font-size: 18px;
                                    line-height: 1.3;
                                    font-weight: 500;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .data-table {
                        width: 1%;
                        flex: 1;
                        display: flex;
                        border-left: 1px solid #EAEAEA;
                        border-bottom: 1px solid #EAEAEA;
                        .row {
                            flex: 1;
                            text-align: center;
                            .item {
                                min-height: 21px;
                                padding: 10px;
                                border-top: 1px solid #EAEAEA;
                                border-right: 1px solid #EAEAEA;
                                .gray {
                                    color: #999;
                                }
                            }
                            &.row-2 {
                                display: flex;
                                flex-direction: column;
                                .item-full {
                                    height: 1%;
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
                .detail-question {
                    .question-type-item {
                        padding: 20px 20px 0;
                        .question-type-title {
                            .title {
                                font-size: 18px;
                                color: #333;
                                font-weight: bold;
                            }
                            .subtitle {
                                font-size: 16px;
                                color: #666;
                            }
                        }
                        .subject-item {
                            padding-bottom: 20px;
                            border-bottom: 1px solid #EAEAEA;
                            .subject-answer {
                                display: flex;
                                margin-top: 15px;
                                .subject-left {
                                    width: 1%;
                                    flex: 1;
                                    .subject-title {
                                        font-size: 16px;
                                        color: #333;
                                        font-weight: 500;
                                        span {
                                            ::v-deep p {
                                                margin: 0;
                                                display: inline;
                                            }
                                            &.score {
                                                color: #409eff;
                                                opacity: 0.7;
                                                margin: 0 10px;
                                            }
                                        }
                                    }
                                    .answer-wrapper {
                                        display: flex;
                                        margin-top: 10px;
                                        .answer-tags {
                                            color: #FF0000;
                                            background: #FFDFDF;
                                            display: inline-block;
                                            padding: 6px 10px;
                                            border-radius: 4px;
                                        }
                                        .answer-content {
                                            width: 1%;
                                            flex: 1;
                                            margin-left: 20px;
                                            .answer {
                                                color: #409eff;
                                                font-weight: 500;
                                            }
                                            .score {
                                                margin-top: 20px;
                                            }
                                        }
                                    }
                                }
                                .subject-right {
                                    margin-left: 20px;
                                }
                            }
                            .subject-analysis {
                                margin: 15px 0 0;
                                background: #F5F5F5;
                                padding: 15px;
                                span {
                                    ::v-deep p {
                                        margin: 0;
                                        display: inline;
                                    }
                                }
                            }
                        }
                    }
                    .detail-commit {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
</style>